import { intersection, isNil } from 'lodash-es';
import Bugsnag from '@bugsnag/js';

import { Scope } from 'types/permissions';
import { Storage } from './storage';
import moment from 'moment-timezone';
import { TIMEZONE } from './constants';

/**
 * Axios Error Handler
 *
 * @param err
 * @constructor
 */
export const AxiosErrorHandler = (err: any) => {
  Bugsnag.notify(err);
  if (!isNil(err.response) && err.response.status === 401) {
    // TODO
  }
};

/***
 * Represents an error message.
 * @param {any} response - The response object.
 * @returns {string} - The error message.
 */
export const ErrorMessage = (response: any): Error => {
  return Error(response?.data?.message || 'Error while loading the data');
};

/**
 * Format currency
 *
 * @param value
 * @param maximumFractionDigits
 * @constructor
 * @return string | null;
 */
export const FormatCurrency = (value: number | null | undefined, maximumFractionDigits?: number | undefined) => {
  if (isNil(value)) return undefined;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: isNil(maximumFractionDigits) ? 2 : maximumFractionDigits
  }).format(value);
};

/**
 * FieldSorter usable for table string fields
 *
 * @param str_a
 * @param str_b
 * @constructor
 */
export const FieldSorter = (str_a: string | null, str_b: string | null) => {
  if (str_a && str_b) {
    return str_a?.localeCompare(str_b);
  } else if (!str_a && str_b) {
    return -1;
  } else if (str_a && !str_b) {
    return 1;
  } else {
    return 0;
  }
};

/**
 * Sort Currency
 *
 * @param a
 * @param b
 * @constructor
 */
// export const SortCurrency = (a: any, b: any) => {
//   let first = '';
//   let second = '';
//
//   if (!isNull(a)) {
//     const paddedA: string = (Math.round(a * 100) / 100).toFixed(2);
//     first = padStart(paddedA.replace('.', ''), 12, '0');
//   }
//
//   if (!isNull(b)) {
//     const paddedB: string = (Math.round(b * 100) / 100).toFixed(2);
//     second = padStart(paddedB.replace('.', ''), 12, '0');
//   }
//
//   return first.localeCompare(second);
// };

/**
 * Currency Parser
 *
 * @param val
 */
// export const CurrencyParser = (val: any) => {
//     try {
//         // for when the input gets clears
//         if (typeof val === "string" && !val.length) {
//             val = "0.0";
//         }
//
//         // detecting and parsing between comma and dot
//         const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
//         const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
//         let reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
//         reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
//         //  => 1232.21 €
//
//         // removing everything except the digits and dot
//         reversedVal = reversedVal.replace(/[^0-9.]/g, "");
//         //  => 1232.21
//
//         // appending digits properly
//         const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
//         const needsDigitsAppended = digitsAfterDecimalCount > 2;
//
//         if (needsDigitsAppended) {
//             reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
//         }
//
//         return Number.isNaN(reversedVal) ? 0 : reversedVal;
//     } catch (error) {
//         console.error(error);
//     }
// };

/**
 * Currency Formatter
 *
 */

export const currencyFormatter = (value: any) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 *
 * @param val
 * @return string
 * @constructor
 */
// export const DecimalString = (val: string | number) => {
//   return val.toString() !== ''
//     ? val
//         .toString()
//         .replace(/[^\d.-]/g, '')
//         .replace('.', 'x')
//         .replace(/\./g, '')
//         .replace('x', '.')
//         .replace(/(?!^)-/g, '')
//     : '';
// };

// export const SelectFuzzyFieldValue = (str: string, fields: Array<any>) => {
//   if (fields.length === 0) return undefined;
//   const fieldsArray: Array<string> = [];
//   for (let i = 0; i < fields.length; i++) {
//     fieldsArray.push(fields[i].label);
//   }
//   const fuzzyMatch: string = closest(str, fieldsArray);
//
//   const [match] = fields.filter((item: any) => item.label === fuzzyMatch);
//
//   return match.value;
// };

/**
 * Convert number string to number
 *
 * @param value
 * @return number | null
 * @constructor
 */
// export const ToNumber = (value: string | number | null) => {
//   if (isNil(value)) return null;
//   if (isNumber(value)) return value;
//
//   const cleanedNumber = value.replace(/\(/, '-').replace(/[^0-9.-]/g, '');
//
//   return Number.isNaN(cleanedNumber) || cleanedNumber === '-'
//     ? null
//     : Number(value.replace(/\(/, '-').replace(/[^0-9.-]/g, ''));
// };

/**
 * Return random number between provided min and max numbers
 *
 * @param min
 * @param max
 * @constructor
 */
export const RandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};

// /**
//  * Extract simple array from an array of objects.
//  *
//  * @param arr
//  * @param column
//  * @constructor
//  */
// export const ExtractColumn = (arr: Array<any>, column: string) => arr.map((x: any) => x[column]);

// export const skusToArray = (skusString: string) => {
//   const skus: Array<string> = skusString.replace(/ /g, '').replace(/\r?\n/g, ',').split(',');
//   remove(skus, (sku: string) => sku === '');
//   return uniq(skus);
// };

/**
 * Simple ACL Check
 *
 * @param scopes
 * @constructor
 */
export const ACLCheck = (scopes: Array<Scope>) => {
  const permissionsString = Storage.get('permissions');
  if (!permissionsString) return false;

  const permissions: Array<string> = JSON.parse(permissionsString);
  const overlap: Array<string> = intersection(permissions, scopes);

  return overlap.length > 0;
};

/**
 * Return date/time for comment based
 *
 * @param datetime
 */
export const getCommentDateTime = (datetime: Date) => {
  const now = moment();
  const timePosted = moment(datetime);
  const diff = now.diff(timePosted, 'hours');
  return diff <= 24 ? moment(datetime).tz(TIMEZONE).fromNow(false) : moment(datetime).utc().format('lll');
};

export const regionalCurrencyFormatter = (value: number, style?: string) => {
  // use an intl library if more use cases arise
  const locale = navigator.language;
  return new Intl.NumberFormat(locale, {
    style,
    currency: style === 'currency' ? 'USD' : undefined,
    currencyDisplay: 'narrowSymbol'
  }).format(value);
};

/**
 * Extracts the first and last letters of each word from a given string.
 *
 * @param {string} str - The input string.
 * @returns {string} - The first and last letters combined as a single string.
 */
export const firstLetters = (str: string): string => {
  // Split the string into words
  const words = str.trim().split(/\s+/);

  // Check if there are any words
  if (words.length === 0) {
    return '';
  }

  // Get the first and last word.  If there is only one word, set lastLetter to an empty string.
  const firstLetter = words[0].charAt(0);
  const lastLetter = words.length >= 2 ? words[words.length - 1].charAt(0) : '';

  // Combine and return the first letters
  return firstLetter + lastLetter;
};

/**
 * Cleans and validates a number value.
 *
 * @param {number | string | null} num - The number value to clean and validate.
 * @param {number} [defaultValue=0] - The default value to return if num is null or undefined.
 * @returns {number} - The cleaned and validated number value, or the default value if num is null or undefined.
 */
export const NumberClean = (num: number | string | null, defaultValue?: number): number => {
  if (typeof num === 'number') return num;
  if (!num) return defaultValue || 0;
  return Number(num.replace(/[^0-9.]/g, ''));
};

/**
 * Extracts unique values from a specified field in an array of objects.
 *
 * @param {any[]} data - The array of objects to process.
 * @param {string} fieldName - The field name whose values should be extracted.
 * @returns {any[]} An array of unique values extracted from the specified field.
 */
export const extractUniqueValues = (data: any[], fieldName: string): any[] => {
  // Extract the specified field from each object and create a Set to ensure unique values
  const valuesSet = new Set(data.map((item: any) => item[fieldName]));

  // Convert the Set back to an array
  return Array.from(valuesSet);
};
